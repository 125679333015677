import React from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Link,
  Chip,
} from "@mui/material";
import { productViewerUrl } from "../../config";

export const ProductCard = ({ product = {}, viewType }) => {
  const defaultCoverPath = require("../../images/image.png");

  const colors = [
    {
      id: 1,
      name: "color-1",
    },
    {
      id: 2,
      name: "color-2",
    },
    {
      id: 3,
      name: "color-3",
    },
    {
      id: 4,
      name: "color-4",
    },
    {
      id: 5,
      name: "color-5",
    },
    {
      id: 6,
      name: "color-6",
    },
    {
      id: 7,
      name: "color-7",
    },
    {
      id: 8,
      name: "color-8",
    },
    {
      id: 9,
      name: "color-9",
    },
    {
      id: 10,
      name: "color-10",
    },
  ];

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        // margin: "0 20px 20px 0",
        boxShadow: "none",
        borderRadius: "10px",
        ...(viewType === "grid"
          ? {
              flexDirection: "column",
              width: 200,
            }
          : {}),
        ":hover":
          product.status === "Expired" || product.status === "Paused"
            ? { background: "rgba(0, 0, 0, 0.4)" }
            : { background: "rgba(0, 176, 255, 0.1)" },
        ...(product.status === "Expired" || product.status === "Paused"
          ? { background: "rgba(0, 0, 0, 0.4)" }
          : {}),
      }}
      className={
        product.status === "Expired" || product.status === "Paused"
          ? ""
          : `${colors[Math.floor(Math.random() * 9) + 1].name}`
      }
    >
      <CardActionArea
        disabled={product.status === "Expired" || product.status === "Paused"}
        height="100%"
      >
        <Link
          href={`${productViewerUrl}/?productid=${product.id}`}
          target="_self"
          rel="noreferrer"
          sx={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          <Box>
            {viewType === "grid" && (
              <Box sx={{ position: "relative" }}>
                {(product.status === "Expired" ||
                  product.status === "Paused") && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 2,
                      background: "rgba(0, 0, 0, 0.4)",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      sx={{
                        fontWeight: "400",
                        fontSize: "17px",
                        textAlign: "center",
                        color: "#fff",
                      }}
                      component="div"
                    >
                      {product.status === "Expired"
                        ? "Expired"
                        : "Paused (Payment failed)"}
                    </Typography>
                  </Box>
                )}
                <CardMedia
                  component="img"
                  image={
                    product.productCoverPath
                      ? product.productCoverPath
                      : defaultCoverPath
                  }
                  alt="cover image"
                />
              </Box>
            )}
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                sx={{
                  fontWeight: "400",
                  fontSize: "17px",
                  ...(viewType === "grid"
                    ? { marginBottom: "0.35em" }
                    : { marginBottom: "0" }),
                  textAlign: "center",
                }}
                component="div"
              >
                {product.productName}
              </Typography>
              {viewType === "list" &&
                (product.status === "Expired" ||
                  product.status === "Paused") && (
                  <Typography
                    gutterBottom
                    variant="h6"
                    sx={{
                      fontWeight: "400",
                      fontSize: "17px",
                      margin: "1rem 0 0",
                      textAlign: "center",
                      color: "#fff",
                    }}
                    component="div"
                  >
                    {product.status === "Expired"
                      ? "Expired"
                      : "Paused (Payment failed)"}
                  </Typography>
                )}
                <Box sx={{display: "flex", justifyContent: "center", margin: "20px 0 0"}}>
                  <Chip label={product.productType} color="primary" />
                </Box>
            </CardContent>
          </Box>
        </Link>
      </CardActionArea>
    </Card>
  );
};
