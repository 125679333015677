import { useState, useEffect, useCallback } from "react";
import { Box, Typography, CircularProgress, Button, Link } from "@mui/material";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { PageLayout } from "./components/PageLayout";
import { SignInPage } from "./pages/SignInPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { useLogin } from "./hooks/useLogin";
import { MyBooksPage } from "./pages/MyBooksPage";
import "./App.css";
import { ProfilePage } from "./pages/ProfilePage";
import { CoursePage } from "./pages/CoursePage";
import { useStore } from "./Store";
import { PageNotFound } from "./pages/PageNotFound";
import { SignUpPage } from "./pages/SignUpPage";
import { HomePage } from "./pages/HomePage";
import { SubscriptionsPage } from "./pages/SubscriptionsPage";
import { ChildPage } from "./pages/ChildPage";
import { CheckoutSuccess } from "./pages/CheckoutSuccess";
import { CheckoutError } from "./pages/CheckoutError";
import { VerificationPage } from "./pages/VerificationPage";

export const App = () => {
  const [open, setOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoggedin, user, userNotExist] = useLogin();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const userRole = useStore((state) => state.userRole);
  const isChildHasNoParent = useStore((state) => state.isChildHasNoParent);
  const updateUser = useStore((state) => state.updateUser);
  const resetStates = useStore((state) => state.resetStates);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedin) {
      updateUser(user);
      setIsAuthenticated(true);
      setLoadingMsg("");
      setLoading(false);
    }
  }, [isLoggedin, user, updateUser]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const loadingCallback = useCallback((isLoading, msg) => {
    setLoading(isLoading);
    setLoadingMsg(msg);
  }, []);

  return (
    <>
      {isAuthenticated && !userNotExist ? (
        <PageLayout
          open={open}
          user={user}
          isAuthenticated={isAuthenticated}
          toggleDrawer={toggleDrawer}
          sx={{ display: "block" }}
          userRole={userRole}
          Auth={Auth}
        >
          <Routes>
            {userRole === "Parent" ? (
              <>
                <Route path="/" element={<HomePage user={user} loadingCallback={loadingCallback} />} />
                <Route
                  path="/billing"
                  element={
                    <SubscriptionsPage
                      loadingCallback={loadingCallback}
                      user={user}
                    />
                  }
                />
                <Route
                  path="/profile"
                  element={<ProfilePage loadingCallback={loadingCallback} />}
                />
                <Route
                  path="/child/:id"
                  element={<ChildPage loadingCallback={loadingCallback} />}
                />
                <Route
                  path="/checkoutsuccess/"
                  element={
                    <CheckoutSuccess loadingCallback={loadingCallback} />
                  }
                />
                <Route
                  path="/checkouterror/"
                  element={<CheckoutError loadingCallback={loadingCallback} />}
                />
              </>
            ) : (
              <>
                <Route
                  path="/"
                  element={
                    <MyBooksPage
                      loadingCallback={loadingCallback}
                      productType="Course"
                    />
                  }
                />
                {isChildHasNoParent && (
                  <Route
                    path="/billing"
                    element={
                      <SubscriptionsPage
                        loadingCallback={loadingCallback}
                        user={user}
                      />
                    }
                  />
                )}
                <Route
                  path="/profile"
                  element={<ProfilePage loadingCallback={loadingCallback} />}
                />
                {/* <Route
                  path="/course/:id"
                  element={<CoursePage loadingCallback={loadingCallback} />}
                /> */}
              </>
            )}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </PageLayout>
      ) : isAuthenticated && userNotExist ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 9999,
            background: "rgba(238, 238, 238, 0.5)",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              No product purchased.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <Link href="https://www.ninjalearner.com/#pricing">Please click here to make the purchase.</Link>
            </Typography>
            <Button
              variant="contained"
              onClick={async () => {
                if (Auth) {
                  await Auth.signOut();
                  navigate("/");
                  window.location.reload();
                  localStorage.clear();
                  sessionStorage.clear();
                  resetStates()
                }
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? "#f2faff" : "#f2faff",
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Routes>
            <Route
              path="/"
              element={<SignInPage loadingCallback={loadingCallback} />}
            />
            <Route
              path="/login"
              element={<SignInPage loadingCallback={loadingCallback} />}
            />
            <Route
              path="/forgetpassword"
              element={<ForgotPasswordPage loadingCallback={loadingCallback} />}
            />
            <Route
              path="/signup"
              element={<SignUpPage loadingCallback={loadingCallback} />}
            />
            <Route
              path="/verify"
              element={<VerificationPage loadingCallback={loadingCallback} />}
            />
          </Routes>
        </Box>
      )}

      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 9999,
            background: "rgba(238, 238, 238, 0.5)",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography variant="body2" color="text.secondary">
              {loadingMsg}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
