import create from "zustand";
import { persist } from "zustand/middleware";

export const useStore = create(
  persist((set, get) => ({
    user: {},
    userRole: "",
    children: [],
    isChildHasNoParent: false,
    indParentId: "",
    updateUser: (item) => set((state) => ({ user: item })),
    setUserRole: (item) => set((state) => ({ userRole: item })),
    setIsChildHasNoParent: (value) =>
      set((state) => ({ isChildHasNoParent: value })),
    setIndParentId: (id) => set((state) => ({ indParentId: id })),
    updateStates: (new_state) =>
      set((state) => ({
        ...state,
        ...new_state,
      })),
    resetStates: () => {
      set((state) => ({
        ...state,
        user: {},
        userRole: "",
        children: [],
        isChildHasNoParent: false,
        indParentId: "",
      }))
    }
  }))
);
